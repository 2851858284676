@import '../../../sass/variables';
@import '../../../sass/functions';

.whats-hot {
    h1 {
        margin-bottom: 40px;
    }

    .button-wrapper {
        float: right;

        .view-all {
            margin: 10px 5px;
        }
    }

    .whats-hot-block {
        margin-bottom: 1px;
        padding-left: 1px;
        padding-right: 1px;

        .whats-hot-block-inner {
            background-color: $proDarkBlue;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 210px;
            overflow: hidden;
            position: relative;

            &::after {
                background-color: $black;
                content: '';
                display: block;
                height: 100%;
                left: 0;
                opacity: 0;
                position: absolute;
                top: 0;
                transition: opacity 0s ease-in-out;
                width: 100%;
            }

            &:hover,
            &:focus,
            &:active {
                &::after {
                    opacity: 0.25;
                }

                .block-link-homepage-text {
                    align-items: center;
                    background-image: none;
                    position: relative;
                }
            }

            .block-link-text {
                background-color: hexToString($proBlue, 0.65) !important;
                color: $white;
                left: 0;
                padding: 15px;
                position: absolute;
                top: 0;
                transition: all 0.3s ease-in-out;
                width: 100%;
                z-index: 2;

                .h4 {
                    color: $white;
                    margin-bottom: 0;
                    font-family: $romanFontStack;
                }

                h3 {
                    color: $white;
                    line-height: 24px;
                    margin-bottom: 0;
                }
            }

            .block-link-bottom {
                bottom: 0;
                top: auto;
            }
        }

        .animate-from-top {
            &::after {
                content: '';
                position: absolute;
                transform-origin: 50% 0;
            }
        }

        .block-link-dark {
            .block-link-text {
                background-color: hexToString($proDarkBlue, 0.65) !important;
            }
        }

        .block-link-transparent {
            .block-link-text {
                background-color: transparent !important;
            }
        }
    }

    .whats-hot-block-wrapper {
        padding-left: 1px;
        padding-right: 1px;
    }

    /* homepage variant */

    .whats-hot-homepage-block {
        padding: 18px;

        .whats-hot-homepage-block-inner {
            background-color: $black;
            height: 353px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            position: relative;

            &:hover,
            &:focus,
            &:active {
                &::after {
                    opacity: 1;
                    background-color: hexToString($solBlue, 0.9) !important;
                    transition: opacity 0s ease;
                    border: 1px solid $solBlue;
                }
            }

            .block-link-homepage-text {
                background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 20%, rgba(0, 0, 0, 0) 75%);
                color: $white;
                padding: 15px;
                position: absolute;
                width: 100%;
                z-index: 2;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: flex-end;

                h3 {
                    color: $white;
                    line-height: 24px;
                    margin-bottom: 1px;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .whats-hot {
        .button-wrapper {
            display: block;
            margin-left: auto;
            margin-right: auto;
            padding: 0 20px;
            width: 100%;

            .view-all {
                margin: auto;
                width: 100%;
            }
        }
        .whats-hot-block {
            float: left;
            width: 50%;

            .block-link-top,
            .block-link-bottom {
                .block-link-text {
                    bottom: auto;
                    top: 0;
                }
            }

            .block-link-light,
            .block-link-dark {
                .block-link-text {
                    background-color: hexToString($proDarkBlue, 0.65) !important;
                }
            }

            .whats-hot-block-inner {
                .block-link-text {
                    bottom: 0;
                    top: auto;

                    h4,
                    h5 {
                        color: $white !important;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    .whats-hot {
        .whats-hot-block {
            float: none;
            width: 100%;
        }
    }
}
